<template>
  <v-app>
    <v-container class="my-6 mx-auto scoreTables">
      <v-card>
        <div
          class="card-header-padding"
          style="padding-top: 2rem; padding-bottom: 0rem"
        >
          <v-row>
            <v-col>
              <v-select
                v-if="currenSelectedLesson"
                return-object
                v-model="currenSelectedLesson"
                :items="lessons"
                item-text="name"
                item-value="id"
                label="Xичээл сонгоx"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                return-object
                v-model="selectedMonth"
                :items="[1, 2, 3, 4, 5, 9, 10, 11, 12]"
                item-text="name"
                item-value="id"
                label="Сар сонгоx"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <template v-if="currenSelectedLesson && selectedMonth">
          <v-tabs v-model="tabClassGroups" class="overflow-hidden px-6">
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              class="py-0"
              v-for="(classGroup, tabIndex) in currenSelectedLesson.classGroups"
              :href="'#tabClassGroups-' + (tabIndex + 1)"
              :key="'tabClassGroups' + tabIndex"
            >
              {{ classGroup.departmentName }}{{ classGroup.classGroupName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabClassGroups" class="mt-4">
            <v-tab-item
              :value="'tabClassGroups-' + (tabIndex + 1)"
              v-for="(classGroup, tabIndex) in currenSelectedLesson.classGroups"
              :key="'tabItemClassGroups' + tabIndex"
            >
              <!-- <StudentsByClassGroupTable v-if="students[classGroup.classGroupRef.path]"
          :students="students[classGroup.classGroupRef.path]"
        ></StudentsByClassGroupTable> -->

              <AttendanceLessonByStudents
                v-if="
                  students != null && students[classGroup.classGroupRef.path]
                "
                :selectedMonth="selectedMonth"
                :lesson="currenSelectedLesson"
                :students="students[classGroup.classGroupRef.path]"
              ></AttendanceLessonByStudents>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <!-- <StudentsByClassGroup
          v-if="students"
          :lesson="lesson"
          :students="students"
        ></StudentsByClassGroup> -->
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AttendanceLessonByStudents from "@/views/Pages/Irz/AttendanceLessonByStudents";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    AttendanceLessonByStudents,
    //StudentsByClassGroup
  },
  data: () => ({
    currenSelectedLesson: null,
    tabClassGroups: null,
    selectedMonth: null,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    students: null,
    lessons: null,
    xlessons: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;

          console.log(tmp);
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
  },
  watch: {
    selectedMonth(val) {
      console.log(val);
    },
    currenSelectedLesson(val) {
      this.path = val.ref.path;
      this.currenSelectedLesson = val;
      this.students = [];
      if (
        this.currenSelectedLesson != null &&
        this.currenSelectedLesson != undefined &&
        this.currenSelectedLesson.classGroups != null &&
        this.currenSelectedLesson.classGroups != undefined
      ) {
        this.currenSelectedLesson.classGroups.forEach((cgroup) => {
          fb.db
            .collection(this.yyschool + "students")
            .where("classGroup-2021", "==", cgroup.classGroupRef)
            .get()
            .then((docs) => {
              this.students[cgroup.classGroupRef.path] = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.id = doc.id;
                student.ref = doc.ref;
                student.classGroupRef = cgroup.classGroupRef;
                student.attendances = null;
                student.ref
                  .collection("attendances")
                  .doc(this.currenSelectedLesson.id)
                  .collection("attendance")
                  .onSnapshot((docs) => {
                    student.attendances = [];
                    if (!docs.empty) {
                      docs.forEach((doc) => {
                        if (doc.exists) {
                          // console.log(doc.data()); alga bolgoldoo
                          let x = doc.data();
                          x.ref = doc.ref;
                          x.id = doc.id;
                          student.attendances.push(x);
                        }
                      });
                    }
                  });
                this.students[cgroup.classGroupRef.path].push(student);
              });
            });
        });
      }
    },
  },
  created() {
    //TODOX
    this.path = this.yyschool + "lessons/ePwooaz9bnRiOHpOaif8" 
    this._setupp();
    this.headerNames = [
      {
        text: "xxx",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];
    for (var x of Array(12).keys()) {
      x++;
      this.headerNames.push({
        text: x,
        align: "start",
        sortable: true,
        value: x,
      });
    }
    this.bodyNames = ["firstName"];
  },
  mounted() {
    // fb.db
    //   .doc(this.path)
    //   .get()
    //   .then((doc) => {
    //     let lesson = doc.data();
    //     lesson.id = doc.id;
    //     lesson.ref = doc.ref;
    //     this.currenSelectedLesson = lesson;
    //     this.students = [];
    //     if (lesson.classGroups !== undefined && lesson.classGroups != null) {
    //       lesson.classGroups.forEach((cgroup) => {
    //         fb.db
    //           .collection("/schools/dfsadf/students")
    //           .where("classGroup-2021", "==", cgroup.classGroupRef)
    //           .get()
    //           .then((docs) => {
    //             this.students[cgroup.classGroupRef.path] = [];
    //             docs.forEach((doc) => {
    //               let student = doc.data();
    //               student.id = doc.id;
    //               student.ref = doc.ref;
    //               student.classGroupRef = cgroup.classGroupRef;
    //               this.students[cgroup.classGroupRef.path].push(student);
    //             });
    //           });
    //       });
    //     }
    //   });
  },
  methods: {
    async _setupp() {
      var schoolRef = await fb.db
        .collection("schools")
        .doc(this.yyschool)
        .get();

      if (schoolRef.exists && this.userData["myClassRef-2021"].parent.parent) {
        let school = schoolRef.data();
        school.id = schoolRef.id;
        school.ref = schoolRef.ref;
        this.school = school;

        this.school.ref
          .collection("xalllessons")
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            this.xlessons = [];
            querySnapshot.forEach((doc) => {
              let categoryLesson = doc.data();
              categoryLesson.id = doc.id;
              categoryLesson.ref = doc.ref;
              categoryLesson.ref
                .collection("departments-2021")
                .doc(this.userData["myClassRef-2021"].parent.parent.id)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    let lesson = doc.data();
                    lesson.id = doc.id;
                    lesson.ref = doc.ref;
                    categoryLesson.lesson = lesson;
                    // console.log(lesson);
                    if (this.currenSelectedLesson == null) {
                      this.currenSelectedLesson = lesson;
                    }
                    this.xlessons.push(categoryLesson);
                  }
                });
            });
          });
      }

      var query = fb.db.collection(this.yyschool+ "lessons");

      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          if (this.currenSelectedLesson == null) {
            this.currenSelectedLesson = lesson;
            this.students = [];
            if (
              lesson.classGroups !== undefined &&
              lesson.classGroups != null
            ) {
              lesson.classGroups.forEach((cgroup) => {
                fb.db
                  .collection(this.yyschool+ "students")
                  .where("classGroup-2021", "==", cgroup.classGroupRef)
                  .get()
                  .then((docs) => {
                    this.students[cgroup.classGroupRef.path] = [];
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.id = doc.id;
                      student.ref = doc.ref;
                      student.classGroupRef = cgroup.classGroupRef;
                      student.attendances = null;
                      student.ref
                        .collection("attendances")
                        .doc(lesson.id)
                        .collection("attendance")
                        .onSnapshot((docs) => {
                          student.attendances = [];
                          if (!docs.empty) {
                            docs.forEach((doc) => {
                              if (doc.exists) {
                                // console.log(doc.data()); alga bolgoldoo
                                let x = doc.data();
                                x.ref = doc.ref;
                                x.id = doc.id;
                                student.attendances.push(x);
                              }
                            });
                          }
                        });
                      this.students[cgroup.classGroupRef.path].push(student);
                    });
                  });
              });
            }
          }
          this.lessons.push(lesson);
        });
      });
    },
  },
};
</script>
 <style>
</style>

 